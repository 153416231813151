.content-box.glass {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
}

.content-box.full-width {
    position: relative;
    margin: auto 0;
    left: calc((100dvw - var(--sizing-width)) / -2);
    padding-left: calc((100dvw - var(--sizing-width)) / 2 + 10px);
    padding-right: calc((100dvw - var(--sizing-width)) / 2 + 10px);
}

.content-box.glass {
  --input-border-color: var(--color-neutral-light);
  color: var(--color-neutral-light);
}
.content-box.glass .input-label,
.content-box.glass label { color: var(--color-neutral-light); }

.content-box.glass a:not(:focus):not(:hover):not(:focus-visible) {
  color: var(--color-neutral-light);
}

.content-box.center {
  text-align: center;
}

.glass #cat_code::before {
    border-left: 1px solid var(--color-neutral-light);
    border-bottom: 1px solid var(--color-neutral-light);
  }
