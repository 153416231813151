.pagination-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 787px) {
  .pagination-button-container {
    flex-direction: column;
    justify-content: space-around;
  }
}
