.dialog-wrapper {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: 0.3s fadein;
}

@keyframes fadein {
  from  {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog {
  margin: 2rem auto;
  max-width: calc(100dvw - 2rem);
  max-height: calc(100dvh - 4rem);
  overflow: auto;
}

.dialog > .content-box {
  height: fit-content;
}
.dialog-wrapper > .dialog > .content-box {
  margin: 0 auto;
}

.dialog-centerall {
  text-align: center;
}

.dialog-centerall .dialog-footer {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.25em;
  gap: 5rem;
  margin-top: 1rem;
}
.dialog-header > * {
  margin-top: 0;
}
.dialog-header .supertitle,
.dialog-header .subtitle {
  display: block;
  font-size: .75em;
  font-weight: 300;
}
.dialog-header > .button {
  margin-top: 0;
  margin-left: auto;
}

.dialog-content {
  margin: 20px 0;
}

.dialog-footer {
  display: flex;
  align-items: center;
}
