input:hover,
input:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus {
  outline: 2px solid var(--color-link-hover);
}

.input-container input[type=select] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  max-width: 100%;
}

.input-container {
  max-width: 100%;
  margin: 10px 0px;
  font-size: var(--font-size-primary);
  font-family: var(--font-family-primary);
  line-height: var(--input-line-height);
  color: var(--input-text-color);
  cursor: text;
  justify-items: left;
  align-items: left;
  padding: 0px;
  min-height: var(--input-min-height);
  position: relative;
}

.input-container .has-options-list {
  width: fit-content;
}

.input-container .inline-message {
  position: absolute;
  margin-top: 10px;
  z-index: 1;
}

.input-checkbox {
  cursor: pointer;
}

textarea,
select,
input {
  border: 0px;
  min-height: var(--input-min-height);
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  border-radius: var(--input-border-radius);
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: var(--input-text-color);
  outline: 1px solid var(--input-border-color);
  background: var(--input-background-color);
  padding: var(--input-padding);
  box-sizing: border-box;
  max-width: 100%;
}

.checkbox-options-list,
.input-select {
  width: fit-content;
  /* user-select: none; // Removing this fence w/o knowing why it's there :) -Bo */
}

.checkbox-options-list {
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 100%;
}

.input-select:disabled {
  color: var(--input-disabled-text-color);
  cursor: auto;
}

.error:focus,
.error:hover,
.error {
  outline: 1px solid red;
}

.helper-text-error {
  position: absolute;
  background-color: white;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: var(--input-border-radius);
  outline: 1px solid red;
  padding: 5px;
  z-index: 1;
}

.input-label {
}

.input-checkbox-option {
  display: flex;
  border: none;
  background: transparent;
  border-radius: var(--input-border-radius);
  width: 100%;
  text-align: left;
  gap: 10px;
  font-size: var(--font-size-primary);
}

.checkbox-button {
  text-align: left;
  width: calc(100% + 30px);
  margin: 0px -15px;
  overflow: ellipse;
}

.input-checkbox-option > .input-label,
.input-checkbox-option > .input-label > label {
  display: inline-block;
  width: 100%;
  pointer-events: none;
}

.checkbox-wrapper {
  width: fit-content;
  display: inline-block;
  padding: 0px 15px;
  max-width: 100%;
}

.checkbox-size-enforcement,
.checkbox-size-enforcement > .input-checkbox-option {
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}

.checkbox-size-enforcement {
  margin-top: -5px;
}

.input-full-width {
  width: 100%;
  flex-shrink: 0;
}

.checkbox-options-list {
  z-index: 50;
  max-height: 65vh;
  overflow: scroll;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.28);
}

.multiple-select-wrapper { position: relative; }
.multiple-select-options-container {
  background: white;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  border-radius: var(--input-border-radius);
  outline: 1px solid var(--input-border-color);
  box-shadow: var(--box-shadow-1);
}
.multiple-select-options-container.upwards {
  bottom: 100%;
}
.multiple-select-wrapper button { cursor: pointer; }
.multiple-select-options-button {
  border-radius: 0;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: var(--input-padding);
}
.multiple-select-options-button:hover,
.multiple-select-options-button:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.multiple-select-option button {
  width: 100%;
}
.multiple-select-options-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--input-border-color);
  padding: 0 10px;
}
.multiple-select-options-top-bar > *:last-child:first-child {
  margin-left: auto;
}
.multiple-select-options-list {
  max-height: 50vh;
  overflow-y: auto;
}

::placeholder {
  color: var(--input-text-color);
  opacity: 1;
}
