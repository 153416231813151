.report-header {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.27);

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.report-header > .report-header-title {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
}
