.library {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2rem;
}
.library a, .library a:link { text-decoration: none; }
.library .content-box {
  min-height: 65vh;
  font-size: min(5rem);
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  color: white;
  transition-property: color;
  transition-duration: 0.2s;
  text-decoration: none;
}
.library a:hover .content-box,
.library a:focus .content-box {
  color: var(--color-secondary);
}
.library a .content-box::after {
  transition-property: width height;
  transition-duration: 0.2s;
}
.library a:hover .content-box::after,
.library a:focus .content-box::after {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.library .content-box.Glossary {
  background-image: url('../../../../public/images/backgrounds/SFA/SFA_glossaryBG.jpg');
}
.library .content-box.Bibliography {
  background-image: url('../../../../public/images/backgrounds/SFA/SFA_biblioBG.jpg');
}

.library .content-box.GrossIssuance {
  background-image: url('../../../images/SFA_reporthero_2X.png');
}

.library .content-box.ServicerPerformance {
  background-image: url('../../../images/SFA_reporthero_2X.png');
}