.sfa-data-search {
  --input-border-radius: 4px;

  --border-radius-big: 1.5rem;
  --border-radius-small: 0.75rem;

  /* If you're wondering why this is like this, it's because some things
   * inherit their font in the normal way, and some have it defined as
   * this custom property. That's the case because when I was working on
   * unifying styling in Winter 2023, I didn't really get how CSS works.
   * Also, there are some components which are holdovers from when we
   * relied on MUI for a lot of things, and which therefor do things in a
   * way that halfway approximates MUI. I don't like MUI anyway. - Sam
   */
  --font-size-primary: 20px;
  font-size: var(--font-size-primary);
}

.sfa-data-search label {
  font-weight: 500;
}

.sfa-data-search h1 {
  margin-top: 14rem;
  margin-bottom: 4rem;
  margin-left: min(6svw, 6.5rem);
  font-size: min(max(24px, 12svw), 4.25rem);
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}

.sfa-data-search .search-options {
  border-radius: var(--border-radius-big);
}
.sfa-data-search .search-options form {
  margin-inline: min(5svw, 5rem);
}

.sfa-data-search .search-results {
  background-color: var(--color-background);
  outline: none;
  border-radius: var(--border-radius-small);
  --padding-inline: min(4.1svw, 3rem);
  padding-left: var(--padding-inline);
  padding-right: var(--padding-inline);
}
.sfa-data-search .search-results::after {
  max-width: 10rem;
}
.sfa-data-search h2 {
  text-align: center;
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
}
.sfa-data-search .searhc-results > h2 {
  margin-top: 1rem;
}
.sfa-data-search .datasearch-graph-wrapper,
.sfa-data-search .dmm-table-wrapper {
  background: white;
  border-radius: inherit;
  padding: 1.5rem;
  border: 1px solid grey;
  font-size: 16px;
}

.sfa-data-search .dmm-table-wrapper .table-toolbar {
  gap: 1rem;
}

.sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button {
  border-color: grey;
  color: grey;
  background-color: transparent;
}
.sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button:focus,
.sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button:hover {
  border-color: var(--color-primary);
  color: white;
}

.sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button:last-child {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}
@media (max-width: 787px) {
  .sfa-data-search .dmm-table-wrapper .table-toolbar .button-group {
    flex-grow: 1;
  }
  .sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button {
    flex-grow: 1;
  }
  .sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button .button-content {
    display: initial;
  }
}
.sfa-data-search .dmm-table-wrapper .table-toolbar .button-group button:last-child svg {
  display: none;
}

.sfa-data-search .dmm-table-wrapper table {
  outline: none;
  border: none;
}

.sfa-data-search .dmm-table-wrapper th {
  background-color: white;
  color: grey;
}

.sfa-data-search .dmm-table-wrapper td,
.sfa-data-search .dmm-table-wrapper th {
  border: none;
}

.sfa-data-search .dmm-table-wrapper tr,
.sfa-data-search .dmm-table-wrapper th {
  border-bottom: 2px solid rgb(235, 235, 235);
}

.sfa-data-search .dmm-table-wrapper tr:nth-child(odd) td:hover,
.sfa-data-search .dmm-table-wrapper tr:nth-child(odd) td {
  background: rgb(248, 248, 248);
}

.sfa-data-search .dmm-table-wrapper td .button-group button {
  margin-top: 0;
  margin-bottom: 0;
}

.sfa-data-search .dmm-table-wrapper td button {
  color: var(--color-secondary);
  background-color: transparent;
  border-color: transparent;
  transition: border-color 0.2s;
}
.sfa-data-search .dmm-table-wrapper td button:hover,
.sfa-data-search .dmm-table-wrapper td button:focus {
  border-color: var(--color-secondary);
}

.sfa-data-search .dialog .content-box {
  border-radius: var(--border-radius-small);
  margin-inline: 4rem;
}

.sfa-data-search .pagination-button-container {
  margin-top: 1rem;
  flex-wrap: wrap;
}

.sfa-data-search .pagination-button-container button:first-child,
.sfa-data-search .pagination-button-container button:last-child,
.sfa-data-search .pagination-button-container button.button-hollow {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-primary);
  transition: background-color 0.2s border-color 0.2s;
}
.sfa-data-search .pagination-button-container button.button-hollow::before,
.sfa-data-search .pagination-button-container button.button-hollow::after { display: none; }

.sfa-data-search .pagination-button-container button:first-child:hover,
.sfa-data-search .pagination-button-container button:last-child:hover,
.sfa-data-search .pagination-button-container button.button-hollow:hover,
.sfa-data-search .pagination-button-container button:first-child:focus,
.sfa-data-search .pagination-button-container button:last-child:focus,
.sfa-data-search .pagination-button-container button.button-hollow:focus {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

@media (max-width: 787px) {
  .sfa-data-search .pagination-button-container {
    justify-content: space-around;
  }
  .sfa-data-search .pagination-button-container button.button-hollow {
    display: none;
  }
}

.sfa-data-search .rounded-corners { border-radius: 15px; }

.sfa-data-search .source-text { margin-top: 1rem; }