.data_source_adder {
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  height: 65vh;
  padding: 5vh 128px 5vh 128px;
}

.error__message {
  border: 1px solid red;
  padding: 10px;
  background-color: pink;
  border-radius: 15px;
}

.unfilled__field {
  border: 1px solid red;
}

.dataSourceAdder-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}