.sfa-hub-back-button {
  opacity: 0.5;
  transition: opacity 0.2s;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .sfa-hub-back-button {
    position: relative;
    display: flex;
    margin: 20px auto;
    justify-content: space-around;
  }
  .sfa-hub-back-button .icon-background {
    position: relative;
    width: 75px;
  }
  .sfa-hub-back-button svg {
    transform: scale(150%);
  }
}
@media (min-width: 1200px) {
  .sfa-hub-back-button {
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: 50px;
  }
  .sfa-hub-back-button .no-text span {
    visibility: hidden;
  }
  .sfa-hub-back-button svg {
    transform: scale(1000%);
  }
  .sfa-hub-back-button .icon-background {
    position: absolute;
    width: 200px;
    top: -85px;
    left: -20px;
  }
  .sfa-hub-back-button button {
    padding-left: 110px;
  }
}

.sfa-hub-back-button button {
  outline: none;
  border: none;
  background: none;
  color: var(--color-sextary);
  font-size: 1.5em;
  font-style: italic;
  position: relative;
}

.sfa-hub-back-button:focus,
.sfa-hub-back-button:hover {
  opacity: 1;
}

.sfa-hub-back-button:focus > *,
.sfa-hub-back-button:hover > * {
  cursor: pointer;
}

.sfa-hub-back-button svg {
  fill: var(--color-sextary);
}
.sfa-hub-back-button .icon-background {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}
