.us-map {
  width: max(50vw, 700px);
  max-width: 90vw;
  margin-bottom: -2rem;
}

.sfa-state-map {
  position: relative;
  isolation: isolate;
  width: 100%;
  --jank: -15px;
  margin-top: var(--jank);
  margin-bottom: var(--jank);
}
.sfa-state-map > * {
  aspect-ratio: 1 / 1;
}
.sfa-state-map::before {
  content: ' ';
  position: absolute;
  inset: 0;
  background: red;
  z-index: -1;
  border-radius: 50%;
  background: var(--color-8);
  opacity: 0.25;
  transform: scale(85%);
  aspect-ratio: 1 / 1;
}

.sfa-state-nonmap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sfa-state-wrapper {
  display: grid;
  grid-template-columns: minmax(100px, 32%) 1fr;
}

.sfa-state-info-box {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  position: sticky;
}

.sfa-state-return-link {
  margin-top: 40px;
}

@media (max-width: 1330px) {
  .sfa-state-wrapper {
    grid-template-columns: 1fr;
  }
  .sfa-state-info-box {
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .sfa-state-info-box {
    flex-direction: column;
  }
}

.sfa-state-toolbar {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  overflow: scroll;
  margin: 10px;
}

.state-info-line > span:first-child {
  font-weight: 700;
  margin-top: 0;
  /* outline: 1px solid cyan; */
}

.state-info-line > span > * {
  display: inline-block;
  margin-top: 0;
  margin-right: 0.5rem;
  /* outline: 1px solid magenta; */
}

.sfa-state-graph-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sfa-state-wrapper .graph-header-titles {
  width: 100%;
}

.sfa-state-wrapper .graph-header abbr {
  line-height: 1.5rem;
  height: 3rem;
  white-space: normal;
  font-size: large;
}

.sfa-state-wrapper .legend {
  min-height: 8rem;
}

/* .state-info-line .button-adornment {
  display: none;
} */


.national-data-link {
  margin-top: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.national-data-link a { font-size: 2rem;}
