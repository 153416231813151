footer {
  padding: var(--footer-vertical-margin);
  margin: 0 auto;
  background-color: var(--bg-color-primary);
}
footer > div {
  margin: auto;
  width: var(--sizing-width);
  min-height: var(--footer-max-height);
  display: flex;
  align-items: top;
  justify-content: space-between;
}
@media (max-width: 1007px) {
  footer > div {
    flex-direction: column;
    align-items: center;
  }
}
footer > div > * {
  text-align: center;
}
footer nav {
  z-index: 0;
}

.footer-link:link, .footer-link:hover, .footer-link:active, .footer-link:visited {
    margin: 40px 50px 20px 50px;
    float: left;
    font: normal normal bold 1.2em/1.2em var(--font-family-primary);
}

.footer-logo {
  max-width: 250px;
  display: block;
}

.footer-DMMHomeLink {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
