.questionAndSubquestions {
  max-width: 100%;
}

.questionAndSubquestions > div:nth-child(n + 2) {
  --margin: 30px;
  margin-left: var(--margin);
  padding-right: var(--margin);
  position: relative;
  box-sizing: border-box;
}

.questionAndSubquestions > div:nth-child(n + 2)::before {
  content: ' ';

  border-left: 1px solid var(--input-border-color);
  border-bottom: 1px solid var(--input-border-color);
  border-radius: 0 0 0 10px;

  height: 80px;
  width: calc(var(--margin) / 2);

  top: -10px;
  left: calc(-1 * var(--margin) / 2);
  position: absolute;
}

.questions.horizontal {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-end;
}

.questions {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.questions .instructions {
  flex-basis: 100%;
  flex-shrink: 0;
}

.questions.horizontal button[type="submit"] {
  margin-left: auto;
}
