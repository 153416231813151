.landing-page-video-wrapper img {
  position: absolute;
  left: 10%;
  top: 3rem;
  width: 80%;
  z-index: -1;
}
.landing-page-video-wrapper {
  position: absolute;
  inset: 0;
  background: #ebe7e1;
  z-index: -1;
  opacity: 0.3;
}

@media (prefers-reduced-motion) {
  .landing-page-video-wrapper {
    display: none;
  }
}

.sfa-tagline {
  color: var(--color-11);
}

.landing-page-text {
  text-align: center;
  padding-top: 5rem;
  line-height: 2;
}
.landing-page-text h2 {
  font-size: 20px;
}
.landing-page-text p {
  font-size: 20px;
}

.landing-page-buttons {
    display: grid;
}

.landing-page-buttons a {
  padding: 10px;
  flex: 1 1 0px;
  text-align: center;
}

.landing-page-buttons button {
  width: 100%;
  max-width: 70vw;
  aspect-ratio: 1 / 1;
  margin: 0;
  border-radius: 50%;
  border: none;
  color: var(--color-text);
  background-color: var(--color-selectors);
  transition: 0.3s;
}

.landing-page-buttons button:hover {
  background-color: var(--color-selectors-hover);
  color: var(--button-text-color-primary);
}


@media (max-width: 899px) {
  .landing-page-buttons {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .landing-page-video-wrapper {
    display: none;
  }
}
@media (min-width: 900px) {
  .landing-page-buttons {
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 1fr;
  }

  .landing-page-buttons button {
    max-width: 18rem;
    font-size: larger;
  }
}
