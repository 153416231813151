.about-page-wrapper {
  position: relative;
  left: calc((100vw - var(--sizing-width)) * -1 / 2);
  overflow-x: hidden;
}

.about_box {
    display: flex;
    padding: 25px 50px 25px 50px;
    margin: 15px 0px 15px 0px;
}

.about-main {
  background: #1a2124;
  color: white;
  border-radius: 0;
}

.about-main h1 {
  color: var(--color-secondary);
}

.about-main h3 {
  font-style: italic;
}

@media (max-width: 1007px) {
  .about_box {
    flex-direction: column-reverse;
  }

  .about-us-profiles > * {
    flex-direction: column;
    width: 80%;
  }

  .about-us-profiles {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
}

@media (min-width: 1008px) {
  .about_box {
    flex-direction: row-reverse;
    max-width: 40%;
  }

  .about-main {
    background-image: url("../../images/dmm_aboutbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .image_last {
    flex-direction: column;
    
  }

  .about-us-profiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-us-profiles > * {
    width: 27%;
  }

  .about-us-profiles .dialog-wrapper * {
    overflow: hidden;
  }
}

.about-main {
  min-height: calc(100dvh - 120px);
  padding: 0;
  margin: 0;
}

.our-team {
  background-color: var(--color-primary);
  background-image: url('../../images/DMM_blueBG.jpg');
  margin: 0;
  border-radius: 0;
}


.about-us-title * {
  margin-top: 30px;
  color: white;
  font-size: larger;
}

.about-us-profiles > button {
  flex-direction: column;
  border-radius: 0;
  background: none;
  border: none;
  margin: 10px;
  max-width: 510px;
}

.about-us-profiles > button:focus .content-box,
.about-us-profiles > button:hover .content-box {
  box-shadow: var(--box-shadow-2);
  transform: translate(-2px, -2px);
}

.about-us-profiles > button .content-box {
  box-sizing: border-box;
  height: 100%;
  aspect-ratio: 19 / 20;
  margin: 0;
  transition: transform 0.2s;
}

.about-us-profiles .about-us-details {
  max-height: fit-content;
  max-width: 40%;
}

.profile-box > * {
  text-align: center;
  cursor: pointer;
}

.profile-box img {
  width: 100%;
  aspect-ratio: 5 / 4;
  object-fit: cover;
  object-position: 0% 15%;
}

.about-us-profiles .dialog-wrapper {
  width: 100vw;
  width: 100dvw;
}

.about-us-profiles h2 span {
  font-size: 0.75em;
  display: block;
}

.about-us-title {
  text-align: center;
  font-size: 3rem;
}

.company_list {
    list-style: none;
}

.left_align {
    text-align: left;
    list-style: none;
}

.headshot_photo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-radius: 8px;
};

img {
    width: 100%;
}

.main-image {
    max-width: 100%;
}

.privacy-policy li {
  margin-left: 2rem;
}
