.dashboard-header button,
.dashboard-header input[type=text],
.dashboard-filter-panel button[type=submit],
.dashboard-column button {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  font: inherit;
  box-sizing: border-box;
  display: block;

  transition: background 0.2s;
}
.dashboard-wrapper button { cursor: pointer; }
.dashboard-filter-panel button[type=submit]:focus-visible,
.dashboard-filter-panel button[type=submit]:hover,
.dashboard-header .settings-buttons button:focus-visible,
.dashboard-header .settings-buttons button:hover,
.dashboard-columns-wrapper button:focus-visible,
.dashboard-columns-wrapper button:hover {
  background: rgb(235, 235, 235);
}

.dashboard-wrapper .multiple-select-wrapper button {
  border: none;
}

.dashboard-wrapper .white-background {
  background: white;
  color: black;
  --input-border-color: grey;
}
.dashboard-wrapper .white-background ::placeholder { color: black; }
.dashboard-wrapper .color-secondary { color: var(--color-secondary); }

.dashboard-wrapper .dialog > .content-box {
  border-radius: 5px;
  outline: none;
  overflow: initial;
}
.dashboard-wrapper .dialog > .content-box {
  border-color: var(--color-secondary);
}
.dashboard-wrapper .dialog > .content-box .input-container {
  margin-top: 2rem;
  max-width: fit-content;
  --input-border-color: white;
  --input-border-radius: 5px;
}
.dashboard-wrapper .dialog > .content-box .button-group {
  margin-top: 2rem;
  margin-left: auto;
}
.dashboard-wrapper .dialog > .content-box .button-group .button-hollow {
  box-sizing: border-box;
  border: 2px solid var(--color-secondary);
}
.dashboard-wrapper .dialog > .content-box .button-group .button-hollow::before { display: none; }
.dashboard-wrapper .dialog > .content-box .button-group .button {
  color: white;
}
.dashboard-wrapper .dialog > .content-box .button-group .button:focus,
.dashboard-wrapper .dialog > .content-box .button-group .button:hover {
  color: white;
  background-color: var(--color-secondary);
}
.dashboard-wrapper .dialog > .content-box .button-group .button.button-hollow:focus,
.dashboard-wrapper .dialog > .content-box .button-group .button.button-hollow:hover {
  color: var(--color-secondary);
  background: transparent;
}

.dashboard-wrapper .daysSinceUpdate {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: fit-content;
  gap: 0.5ch;
}
.dashboard-wrapper .daysSinceUpdate svg { height: 1.5ch; }

.dashboard-header {
  display: flex;
  justify-content: space-between;
}

.dashboard-header .settings-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dashboard-header .search {
  position: relative;
}
.dashboard-header .search svg {
  top: 0.5rem;
  left: 0.5rem;
  position: absolute;
  pointer-events: none;
  color: black;
}
.dashboard-header input[type=text] {
  background: white;
  border-radius: 5px;
  padding: 0.5rem;
  height: 40px;
  border: none;
  outline: none;
  width: 40px;
  transition-property: width;
  transition-duration: 0.2s;
  color: transparent;
}
.dashboard-header input[type=text]:focus,
.dashboard-header input[type=text]:not([value=""]) {
  padding-left: 2.5rem;
  width: 40ch;
  color: black;
}
.dashboard-header .settings-buttons button {
  background: white;
  border-radius: 5px;
  padding: 0.5rem;
  height: fit-content;
  aspect-ratio: 1 / 1;
  position: relative;
}
.dashboard-header .settings-buttons button svg {
  transition-property: opacity;
  transition-duration: 0.5s;
}
.dashboard-header .settings-buttons button.filter-button svg:first-child { position: absolute; }
.dashboard-header .settings-buttons button.open .filter,
.dashboard-header .settings-buttons button.closed .clear { opacity: 0; }

.dashboard-filter-panel {
  margin-left: auto;
  width: fit-content;
  padding: 1rem;
  transition-property: max-height opacity margin-bottom;
  transition-duration: 0.5s;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
  position: relative;
  --input-border-radius: 5px;
}
.dashboard-filter-panel.open { max-height: 10rem; opacity: 1; margin-bottom: 2rem; }
.dashboard-filter-panel.closed { max-height: 0; opacity: 0; margin-bottom: 0; }
.dashboard-filter-panel form {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.dashboard-filter-panel button[type=submit] {
  border-radius: 5px;
  background: white;
  height: fit-content;
  padding: var(--input-padding);
  margin: 10px 0;
}
.manuscript-overall-feedback h4,
.dashboard-filter-panel h2 {
  position: absolute;
  margin: 0;
  top: -1rem;
  text-align: center;
  background: var(--bg-color-primary);
  padding: 0 0.5rem;
}

.manuscript-overall-feedback h4 { top: -0.75rem; }

.manuscript-overall-feedback {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid white;
  position: relative;
}

.dashboard-columns-wrapper {
  display: flex;
  overflow: auto;
  gap: 1rem;
  position: relative;
  height: 80dvh;
}
.dashboard-columns-wrapper button:focus-visible,
.dashboard-columns-wrapper button:hover {
  background: rgb(235, 235, 235);
}

.dashboard-column {
  flex-basis: var(--manuscript-width);
  flex-shrink: 0;
  flex-grow: 1;
  min-width: fit-content;
  border-radius: 5px;
}
.dashboard-column h2 {
  width: fit-content;
  text-transform: capitalize;
  background: var(--dashboard-column-header-background, rgba(0, 0, 0, 0.5));
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
}
.dashboard-column h2.overdue { background: var(--color-secondary); }
.dashboard-column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button.dashboard-manuscript {
  background: white;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
  width: var(--manuscript-width);
}
button.dashboard-manuscript.overdue {
  outline: 5px solid var(--color-secondary);
  outline-offset: -5px;
}
.dashboard-manuscript .footer {
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 1rem;
}
.dashboard-manuscript h3 {
  margin: 0;
}
.dashboard-manuscript .footer span.hidden {
  visibility: hidden;
  position: absolute;
}

.dashboard-wrapper .people-link {
  margin-left: auto;
  margin-top: 2rem;
  width: fit-content;
}
