@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&family=Roboto:wght@300;500&display=swap');

html {
  --logo-green: #48bd76;
  --logo-blue: #6293d3;

  --header-max-height: 60px;
  --header-vertical-margin: 30px;
  --footer-max-height: 125px;
  --footer-vertical-margin: 30px;
  --sizing-width: 85vw;

  --color-primary: #3B51EB;
  --color-secondary: #26C778;
  --color-secondary-2: #189650;
  --color-tertiary: #EBCC51;
  --color-quartary: #FD791F;
  --color-quintary: #6B7DCE;
  --color-sextary: #444444;
  
  --color-neutral-light: #F2F2F2;
  --color-neutral: #E6E6E6;
  --color-neutral-mid: #E6E6E6;
  --color-neutral-dark: #BEBEBE;

  --bg-color-primary: var(--color-neutral-light);
  --bg-color-secondary: white;
  --color-link: #000000;
  --color-link-hover: #6b7dd5;

  --font-family-primary: Helvetica;
  --font-size-primary: 16px;
  --font-color-primary: black;
  --line-height-primary: 1.5;

  --input-border-radius: 4px;
  --input-border-color: rgba(0, 0, 0, 0.23);
  --input-background-color: white;
  --input-min-height: 1.14375em;
  --input-padding-x: 14px;
  --input-padding-y: 16.5px;
  --input-padding: var(--input-padding-y) var(--input-padding-x);
  --input-text-color: var(--font-color-primary);
  --input-disabled-text-color: var(--font-color-primary);
  --input-line-height: normal;

  --button-padding: 17.5px 42px;

  --color-success: lightgreen;
  --color-success-text: black;
  --color-success-border: var(--color-secondary-2);

  --color-toast-success: var(--color-secondary-2);

  --box-shadow-1: 0px 4px 8px 0px rgba(0, 0, 0, 0.15), 0 6px 20px rgba(0, 0, 0, 0.15);
  --box-shadow-2: 0px 8px 16px 0px rgba(0, 0, 0, 0.3), 0 12px 40px rgba(0, 0, 0, 0.3);

  font-family: var(--font-family-primary);
  font-size: var(--font-size-primary);
}

body { margin: 0; }

.background{
    background-color: var(--bg-color-primary);
    position: fixed;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    filter: blur(35px);
    opacity: 0.8;

    z-index: -5;
}

.sizing{
    --_head: calc(
      var(--header-max-height)
      +
      var(--header-vertical-margin)
      +
      var(--header-vertical-margin)
    );
    --_foot: calc(
      var(--footer-max-height)
      +
      var(--footer-vertical-margin)
      +
      var(--footer-vertical-margin)
    );
    min-height: calc(100vh - var(--_head) - var(--_foot));
    width: var(--sizing-width);
    margin: auto;
    box-sizing: border-box;
}

@media (max-width : 770px) {
  html {
    --sizing-width: calc(100vw - 20px);
  }
}

a {
  color: var(--color-link);
  text-decoration-color: var(--color-neutral-dark);
  font-family: var(--font-family-primary);
}

a:focus,
a:hover {
    color: var(--color-link-hover);
}

button {
    --button-color-primary: var(--color-primary);
    --button-color-secondary: var(--color-secondary);
    --button-color-tertiary: var(--color-tertiary);
    --button-border-color-primary: var(--color-primary);
    --button-border-color-secondary: var(--color-secondary);
    --button-border-color-tertiary: var(--color-tertiary);
    --button-text-color-primary: white;
    --button-text-color-secondary: black;
    --button-text-color-tertiary: black;
    border-radius: 140px;
}

.button--wide {
    width: 100%;
}

.button--green {
    background: #9CE9D1 0% 0% no-repeat padding-box;
    font: normal normal bold 16px/19px var(--font-family-primary);
    color: #1D2623;
}

button:disabled {
    opacity: 0.4;
}

button:enabled:focus,
button:enabled:hover {
    opacity: 1;
}

table {
  --table-header-bg-color: var(--color-secondary-2);
  --table-header-text-color: white;
}

table.standard,
table.standard td,
table.standard th {
  border-bottom: 1px solid #ddd;
}

table.standard {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}


table.standard th {
  background: var(--table-header-bg-color);
  color: var(--table-header-text-color);
  text-align: left;
  font: normal normal bold 1.125em var(--font-family-primary);
  padding: 10px;
  top: 0;
  position: sticky;
  text-transform: capitalize;
}

table.standard tr > th + th,
table.standard tr > td + td {
  border-left: 1px solid var(--input-border-color);
}
table.standard tbody > tr:hover {
  background-color: var(--input-border-color);
}

table.standard td {
  padding: 5px;
  text-align: right;
  font: normal normal normal 1.125em var(--font-family-primary);
  font-family: var(--font-family-primary);
}

.statistics table.standard td:first-child {
  text-align: left;
}

input {
    border: 2px solid rgba(34, 36, 38, 0.15);
    padding: 10px;
    border-radius: var(--input-border-radius);
}

.home__tagline__h1 {
    font: normal normal bold 4em var(--font-family-primary);
    color: #1D2623;
    letter-spacing: 0px;
    text-align: left;
}

@media (max-width : 770px) {
  .home__tagline__h1 {
      font: normal normal bold 3.2em var(--font-family-primary);
      color: #1D2623;
      letter-spacing: 0px;
      text-align: left;
  }
}

.home__tagline__p {
    text-align: left;
    font: normal normal normal 1.125em var(--font-family-primary);
}

.error_text {
    color: red;
    text-align: center;
    margin: auto;
}

.unimplemented {
    color: blue;
    text-align: center;
    margin: auto;
}

.int_server_err {
    color: blue;
    text-align: center;
    font-size: 1.5em;
    margin: auto;
}

.content-box {
  background-color: var(--bg-color-secondary);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border-width: 0px;
  border-filter: blue(35px);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15), 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: 10px;
}
.content-box.outlined {
  padding: 40px;
}

.text {
  margin: 0px;
  --font-family: var(--font-family-primary);
  --font-size: var(--font-size-primary);
  --font-color: var(--font-color-primary);
  --line-height: var(--line-height-primary);
}

.headerLink {
  margin: 10px 0 10px 0;
}

.body1 {
  margin: 10px 0 10px 0;
}

.h1 {
  --font-size: 6rem;
  --line-height: 1.1;
}

h1,
.h2 {
  margin: 0px;
  --font-size: 4.125rem;
  font-size: var(--font-size);
  --line-height: 1.2;
}

.h3 {
  --font-size: 3rem;
  --line-height: 1.3;
}

.h4 {
  --font-size: 2.125rem;
  --line-height: 1.4;
}

h5,
.h5 {
  font-weight: 500;
  margin: 0;
  --font-size: 1.5rem;
  --line-height: 1.5;
}

.h6 {
  --font-size: 1.25rem;
  --line-height: 1.6;
}

.subtitle1 {
  --font-size: 1rem;
}

.subtitle2 {
  --font-size: 0.875rem;
}

.no-shadow { box-shadow: none; }
.center-text { text-align: center; }
.capitalize { text-transform: uppercase; }
