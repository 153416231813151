.submissions {
  --color-primary: var(--color-secondary);
}

.submissions .submission-form-wrapper {
  max-width: 1000px;
  margin: 4rem auto;
  padding: 2rem;
  outline: 2px solid white;
  border-radius: 15px;
}
.submissions .divider {
  display: block;
  margin: 2rem 0;
  border-top: 2px solid white;
}
.submissions .submission-form-wrapper > * {
  margin-top: 2rem;
}
.submissions .submission-form-wrapper input[type="file"] {
  width: 100%;
  text-align: center;
}
.submissions .submission-form-wrapper .button-group {
  margin-left: auto;
}

.submissions .affirmation-label {
  gap: 1rem;
}
