.hmda-paging-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}
.hmda-paging-flex-item {
  flex-basis: 100%;
}

.hmda-checkbox {
}

.hmda-checkbox-label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
  padding: 2px 5px;
}

.hmda-checkbox-label:hover {
  outline: 1px solid black;
}

.table-wrapper {
  outline: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  box-sizing: border-box;
  max-width: 100%;
  overflow: auto;
}

.table-wrapper th {
}

.year-column {
  position: sticky;
  left: 0;
  box-shadow: 2px 0px 1px 0px rgba(0, 0, 0, 0.5);
}
th.year-column {
  z-index: 5;
}
td.year-column {
  background-color: white;
}
