.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.graph-title {
  white-space: nowrap;
  display: block;
}

.graph-title > * {
  overflow: hidden;
}

.graph-header-titles {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.graph-header-titles * {
  text-align: center;
}

.graph-subtitle * {
  opacity: 80%;
}

.graph-title-text {
  outline: 20px solid green;
  text-decoration: none;
  cursor: help;
  display: block;
  text-overflow: ellipsis;
  overflow: clip;
}

.filter-bar-wrapper {
  padding: 5px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;

  border-radius: var(--input-border-radius);

  height: fit-content;
  transition-duration: 0.5s;
  transition-property: transform, opacity, max-height, margin-bottom;
}
.filter-bar-wrapper.closed {
  transform: translateY(-200%);
  opacity: 0;
  max-height: 0;
  z-index: -1;
  margin-bottom: 0;
}
.filter-bar-wrapper.open {
  transform: translateY(0%);
  opacity: 1;
  max-height: 300px;
  margin-bottom: 2rem;
  z-index: 2;
}

.graph-legend .button-adornment {
  display: none;
}
