.graph {
  position: relative;
  max-width: 100%;
}

.daterangeselector {
  border-radius: var(--input-border-radius);
  gap: 10px;
  margin: auto;
  width: max-content;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
}

.small_daterangeselector {
  width: 100%;
  font-size: 0.75em;
  display: flex;
  flex-wrap: nowrap;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 6rem;
  justify-content: flex-start;
  align-content: flex-start;
}

.legend-label-wrapper {
  width: fit-content;
  max-width: 100%;
  height: 2rem;
  padding-right: 10px;
}

.graph-legend.hidden {
  text-decoration: line-through;
}

.graph-legend {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  --font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
}

.legend-label {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  overflow: hidden;
  cursor: pointer;
}

.legend-label-text {
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: clip;
}

.legend-label:focus,
.legend-label:hover {
  color: black;
}

.legend-tooltip-wrapper,
.legend-tooltip-button {
  display: inline-block;
  color: black;
  border-radius: 0px;
  width: var(--font-size);
  height: var(--font-size);
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
}
.legend-tooltip-button { transform: scale(0.8); }
.legend-tooltip-button:hover,
.legend-tooltip-button:focus {
  background: rgba(0, 0, 0, 0.27);
  cursor: pointer;
}

.legend-tooltip-wrapper {
  padding-right: 20px;
  width: fit-content;
}
.legend-tooltip-wrapper > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(var(--font-size) * 2);
}
