.select-page-progress-indicator-wrapper {
  max-width: fit-content;
  margin: auto;
}

.sources-box {
  min-height: 40vh;
  margin: 20px 0;
}

.bottom-buttons {
  margin: 10px 0;
}

.bottom-buttons > button {
  margin-left: 0;
}

.api-selection-panel {
  outline: 1px solid rgba(0, 0, 0, 0.27);
  border-radius: var(--input-border-radius);
  margin: 20px 0 0 0;
  padding: 0;
}

.api-selection-panel-source,
.api-selection-panel-query {
  margin: 10px;
  display: inline-block;
  width: calc(50% - 20px);
}

.api-selection-panel-radio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.api-selection-panel-radio-button {
  display: flex;
  border-radius: var(--input-border-radius);
  position: relative;
  align-items: center;
  height: calc(var(--line-height-primary) * 1em);
  margin: 10px 0;
  flex-basis: 50%;
  min-width: fit-content;
}

.api-selection-panel-radio-button > input {
  outline: none;
  transform: translateY(calc(var(--line-height-primary) * -0.15em));
  margin-right: 10px;
}

.api-selection-panel-radio-button-focus {
  position: absolute;
  width: 100%;
  border-radius: var(--input-border-radius);
  height: calc(var(--line-height-primary) * 1em);
}

input:checked + .api-selection-panel-radio-button-focus {
  outline: 1px solid rgba(0, 0, 0, 0.27);
}

input:hover + .api-selection-panel-radio-button-focus,
input:focus + .api-selection-panel-radio-button-focus {
  outline: 1px solid black;
}

.report-select-page-upper-panel-titles h5 {
  font-size: 32px;
}
.report-select-page-upper-panel-titles h3 {
  font-size: 64px;
  margin: 0;
}

@media (max-width: 1000px) {
  .api-selection-panel {
    flex-direction: column;
  }
  .api-selection-panel-source,
  .api-selection-panel-query {
    width: calc(100% - 20px);
  }
}

@media (min-width: 1000px) {
  .api-selection-panel {
    flex-direction: row;
  }
  .api-selection-panel-source,
  .api-selection-panel-query {
    width: calc(50% - 20px);
  }

  .report-select-page-upper-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .report-select-page-upper-panel-titles {
    box-sizing: border-box;
  }

  .report-select-page-upper-panel-filter {
    box-sizing: border-box;
    margin-left: 5rem;
  }
}
.available-sources-title-bar {
  display: flex;
  justify-content: space-between;
}
