.sfa-hmda-wrapper {
  display: flex;
  --or-width: 150px;
}

.sfa-hmda-wrapper > *:nth-child(2) {
  width: var(--or-width);
  font-size: 4em;
}

@media (min-width: 1200px) {
  .sfa-hmda-wrapper > *:nth-child(1),
  .sfa-hmda-wrapper > *:nth-child(3) {
    width: calc(50% - (var(--or-width) / 2));
  }
  .sfa-hmda-wrapper > *:nth-child(2) {
    padding-top: 300px;
  }
}

@media (max-width: 1200px) {
  .sfa-hmda-wrapper { flex-direction: column; }
  .sfa-hmda-wrapper > * { width: 100%; }
  .sfa-hmda-wrapper > *:nth-child(2) {
    margin: auto;
    padding: 20px;
  }
}
