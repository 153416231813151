.report-title-input {
  width: 100%;
  font-size: 1.5rem;
  border-radius: var(--input-border-radius);
  outline: 1px solid rgba(0, 0, 0, 0.27);
  border: 0px;
  box-sizing: border-box;
}

.report-title-input:hover,
.report-title-input:focus {
  border: 0px;
  outline: 1px solid black;
}
