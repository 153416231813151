.collection .search-options {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  --vertical-padding: 2rem;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
  align-items: center;
  gap: 5rem;
}
.collection .search-options h1 {
  max-width: min(15ch, 80dvw);
  margin: 2rem 0;
}
.collection .search-options .questions { flex-grow: 1; }
