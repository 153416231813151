.object-array-items {
  border: 1px solid var(--input-border-color);
  width: fit-content;
}
.object-array-items th {
  text-align: left;
}
.object-array-items th,
.object-array-items td {
  padding: 1rem;
}
.object-array-fields label {
  visibility: hidden;
  position: absolute;
}

.object-array-wrapper .options {
  width: fit-content;
  margin: 1rem;
  margin-left: auto;
}
