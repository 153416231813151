.journal {
  color: white;
  --input-text-color: white;
  --input-border-color: white;
  --bg-color-primary: var(--color-primary);
}
.journal option { color: black; }

.journal ::placeholder { color: white; }

.journal .dialog .content-box {
  background-color: var(--bg-color-primary);
  border-radius: 15px;
}
.journal .dialog .dialog-header button {
  transition-property: border background-color;
  transition-duration: 0.15s;
  border: none;
}
.journal .dialog .dialog-header button:not(:hover):not(:focus) {
  background-color: transparent;
}

.journal form { --input-border-color: white; }
.journal .search-options form {
  --input-border-color: var(--color-sextary);
  --input-text-color: var(--color-sextary);
}
.journal .search-results { color: var(--color-sextary); }

.journal form input:disabled,
.journal form select:disabled,
.journal form textarea:disabled {
  color: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}

.journal input,
.journal select,
.journal textarea {
  background: transparent;
}
.journal .button {
  color: white;
  border-radius: 5px;
}
.journal .header {
  display: flex;
  justify-content: space-between;
  margin-top: 20vw;
  gap: 8rem;
}

.journal .header > * { flex-basis: 100%; }
.journal .header .left-side img,
.journal .header .left-side svg {
  max-height: 100%;
}
.journal .header .left-side { display: none; }

.journal .header ul {
  list-style-type: none;
  padding: 0;
  font-size: 2rem;
  margin: 0;
  justify-content: space-between;
}
.journal .header ul li { flex-shrink: 0; }
.journal .header ul a { text-decoration: none; }
.journal .header ul a,
.journal .header .journal-text {
  width: fit-content;
  display: block;
  padding: 1rem;
  padding-left: 0;
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.5));
}
.journal .header .journal-text {
  font-size: 5rem;
  justify-content: space-between;
  align-items: center;
}
.journal .header .journal-text button img,
.journal .header .journal-text button svg {
  --width: 5rem;
  height: var(--width);
  width: var(--width);
  flex-shrink: 0;
  transition: transform var(--animation-time);
}
.journal .header .right-side.menu-open .journal-text button img,
.journal .header .right-side.menu-open .journal-text button svg {
  transform: rotate(-90deg);
}

.journal .header .right-side { --animation-time: 0.2s; }
.journal .header .journal-text span { display: none; }
.journal .header .journal-text button {
  background: transparent;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: inherit;
  border: none;
  color: white;
}
.journal .header .right-side ul {
  position: relative;
  transition-property: transform opacity max-height;
  transition-duration: var(--animation-time);
}
@media (max-width: 1200px) {
  .journal .header .right-side.menu-open ul {
    transform: translateY(0%);
    max-height: 50vh;
    opacity: 1;
    z-index: initial;
  }
  .journal .header .right-side.menu-closed ul {
    transform: translateY(-100%);
    max-height: 0;
    opacity: 0;
    z-index: -1;
  }
}

.journal .header ul a:not(:hover):not(:focus) { color: white; }

@media (min-width: 1200px) {
  .journal .header .left-side { display: initial; }
  .journal .header ul { display: flex; gap: 1rem; }
  .journal .header .right-side { text-align: right; }
  .journal .header ul a,
  .journal .header .journal-text {
    padding-left: 1rem;
    min-width: max-content;
    margin-left: auto; 
  }
  .journal .header .journal-text span { display: initial; }
  .journal .header .journal-text button { display: none; }
}
