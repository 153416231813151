@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&family=Roboto:wght@300;500&display=swap');
.dmm-table-wrapper {
  --bg-color-header: var(--table-header-color);
}

.dmm-table-wrapper table {
  --text-color-header: white;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  line-height: 1.4rem;
  max-height: 70vh;
  overflow: auto;
  display: inline-block;

  border-radius: var(--input-border-radius);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid grey;
}

.dmm-table-wrapper thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
}

.dmm-table-wrapper thead th {
  background-color: var(--table-header-bg-color);
  color: var(--table-header-text-color);
}
.dmm-table-wrapper thead th > div {
  display: flex;
  align-items: flex-start;
}

.dmm-table-wrapper td,
.dmm-table-wrapper th {
  padding: 1rem;
  vertical-align: top;
  font-family: var(--font-family-primary);
}
.dmm-table-wrapper td > *:first-child {
  margin-top: 0;
}
.dmm-table-wrapper td > *:last-child {
  margin-bottom: 0;
}
.table-header-padding {
  color: transparent;
}

.dmm-table-wrapper td {
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 100%;
  background-color: white;
}
.dmm-table-wrapper tr {
  border: 1px solid var(--bg-color-border);
  height: fit-content;
}

.dmm-table-wrapper tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

thead button {
  border: none;
  border-radius: 0;
  background: none;
  text-align: inherit;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  color: inherit;
  display: flex;
  align-items: center;
}
thead button:focus ~ svg,
thead button:hover ~ svg,
thead button:focus,
thead button:hover {
  filter: opacity(0.8);
  cursor: pointer;
  color: inherit;
}

.table-toolbar {
  line-height: 1.45rem;
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  left: 0;
}

.table-toolbar-section {
  display: flex;
  align-items: center;
  font-size: var(--font-size-primary);
  color: var(--input-text-color);
}

.table-toolbar-section select {
  padding: 5px 10px;
  margin: 10px;
  color: inherit;
}

.table-toolbar-section select {
  background: transparent;
  font-size: var(--font-size-primary);
  border: none;
  outline: 2px solid var(--input-text-color);
  border-radius: var(--input-border-radius);
}
.table-toolbar-section select:focus,
.table-toolbar-section select:hover { outline: 1px solid black; }

.table-cell-number { text-align: right; }

.edit-columns-panel {
  width: 700px;
  max-width: 80vw;
}

.edit-columns-panel-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  min-height: 108px;

  margin-bottom: 2%;
  padding: 0 10px;
  border-radius: var(--input-border-radius);
  background: var(--color-neutral-light);

  border: none;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.2s;
}
.edit-columns-panel-row > div {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (max-width: 1007px) {
  .edit-columns-panel-row {
    flex-direction: column;
    align-items: flex-start;
  }
}

.edit-columns-panel-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-columnspanel-row-label {
  display: flex;
}

.edit-columns-panel-row-letter {
  opacity: 20%;
  font-size: 2rem;
}

button.edit-columns-panel-row {
  opacity: 80%;
  font-size: larger;
}

button.edit-columns-panel-row:hover {
  background-color: var(--color-neutral-dark);
}

.stickyLeft {
  position: sticky;
  left: 0;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.2);
}

.non-sorted-column-icon { color: transparent; }
button:focus .non-sorted-column-icon,
button:hover .non-sorted-column-icon { color: inherit; }
