header {
  padding: var(--header-vertical-margin) 0;
  margin: 0 auto;
  background-color: var(--bg-color-primary);
}
header > .header-content {
  max-height: var(--header-max-height);
  width: var(--sizing-width);
  z-index: 10;

  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  margin: auto;
  padding: 10px;
}

.title {
  height: max-content;
}

.logo {
  max-width: 100%;
  max-height: var(--header-max-height);
}

@media (max-width: 787px) {
  .sfa-header div *:nth-child(2) {
    display: none;
  }
}
@media (min-width: 788px) {
  .sfa-header div *:nth-child(2) {
    display: initial;
  }
}
