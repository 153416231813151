.cat-home-wrapper .button {
  --border-color: #00d084;
  --button-border-color-primary: #00d084;
  --button-color-primary: #00d084;
  color: black;
}

.cat-home-wrapper {
  position: relative;
  left: calc((100vw - var(--sizing-width)) * -1 / 2);
  overflow-x: hidden;
}

.cat-home-text {
  background: #1a2124;
  border-radius: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cat-home-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
}

@media (max-width: 1007px) {
  .cat-home-text {
    background: white;
  }
}

@media (min-width: 1008px) {
  .cat-home-text {
      background-image: url("../../images/cat-variant/robgonsalvesblocks.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
}

.cat-home-text {
  min-height: calc(100dvh - 120px);
  padding: 0;
  margin: 0;
}