.progress-indicator {
  max-width: fit-content;
  margin: auto;
}

.progress-indicator .hidden-text {
  width: 0;
  visibility: hidden;
}

.progress-indicator ul {
  list-style-type: none;
  padding: 0;
}

.progress-indicator li {
  min-height: 2.5rem;
}

.progress-indicator li img,
.progress-indicator li svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 2rem;
}

.progress-indicator svg {
  color: var(--color-primary);
}
.progress-indicator svg.rotate {
  color: grey;
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from { transform: rotate(0turn); }
  to { transform: rotate(1turn); }
}
