.info-tooltip { display: inline; }

.button.info-tooltip-button {
  background-color: transparent;
  outline: 0px;
  border: 0px;
  color: inherit;
  margin: 0;
  height: 1rem;
}

.info-tooltip-button:focus,
.info-tooltip-button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

div[role=tooltip] ul {
  padding: 0;
  list-style-type: none;
}
div[role=tooltip] ul ul {
  margin: 0;
  padding: 0 0 0 10px;
}

.dynamic-tooltip {
}
.dynamic-tooltip > * {
  width: fit-content;
  display: inline-block;
}
