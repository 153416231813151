.row-col {
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: 'space-between';
}
.row-col:nth-child(even) {
  color: white;
  position: relative;
}
.row-col:nth-child(even)::before {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;

  width: calc(100vw - (var(--scrollbar-width) / 2));
  left: calc((100vw - var(--sizing-width)) * -1 / 2);

  background-color: var(--color-primary);
  background-image: url('../../images/DMM_blueBG.jpg');
  background-size: cover;
  z-index: -1;
}
.row-col > div {
  flex: 1 1 100%;
}
.row-col > .graph-container {
  margin: 20px auto;
  max-width: 100%;
}
.home-paragraph {
  margin: 0 10px 40px 10px;
  text-align: center;
}
@media (min-width: 1300px) {
  .row-col {
    flex-direction: row;
    margin: 20px 0;
    padding: 70px 10px;
  }
}
.solo-graph-padding {
  max-width: 15%;
}

.home-top {
  min-height: calc(100vh - 310px);
}

.home-bottom {
  margin: 0;
}
