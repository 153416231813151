.save-report {
  margin: 3rem 0;
  width: fit-content;
}

.save-report-small {
  display: flex;
  width: fit-content;
  align-items: flex-end;
  gap: 20px;
}
