.assignees {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
.assignee-avatar {
  display: flex;
  border-radius: 50%;
  width: 2rem;
  aspect-ratio: 1 / 1;
  justify-content: space-around;
  align-items: center;
  font-size: 0.8rem;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
}
.assignee-avatar svg {
  position: absolute;
  width: 1rem;
  left: 1.25rem;
  top: 1rem;
}
.response-icon { color: orange; }
.response-icon.pending { color: teal; }
.response-icon.rejected { color: red; }
.response-icon.accepted { color: green; }

.manuscript-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
}
.manuscript-wrapper.dialog > .content-box {
  --bg-color-primary: white;
  color: black;
  --input-text-color: black;
  --input-border-radius: 5px;
  min-width: 50%;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: -12px 0px 12px rgba(0, 0, 0, 0.25);
  margin-inline: auto;

  max-height: 100%;
  max-width: min(100svw - 2rem, 1000px);
  overflow: auto;

  transition-duration: 0.2s;
  transition-property: min-width opacity;

  border-radius: 15px;
}
.manuscript-wrapper.dialog > .content-box .input-container {
  --input-border-color: grey;
}
.manuscript-wrapper.dialog > .content-box ::placeholder { color: black; }
.manuscript-wrapper.dialog .dialog-header { padding: 1rem; }
.manuscript-wrapper.dialog .dialog-header button { color: black; }
.manuscript-wrapper.empty {
  min-width: 0;
  opacity: 0;
  pointer-events: none;
}
.manuscript-wrapper .heading h2 { margin: 0; }
.manuscript-wrapper .history-wrapper,
.manuscript-wrapper .content-wrapper,
.manuscript-wrapper section {
  padding: 1rem;
}
.manuscript-wrapper section {
  margin-bottom: 2rem;
}
.manuscript-wrapper .abstract button { margin-left: auto; }

.manuscript-wrapper .status,
.manuscript-wrapper .actions {
  border: 1px solid grey;
  border-radius: 5px;
  position: relative;
}
.manuscript-wrapper .actions .align-right { margin-left: auto; }

.manuscript-wrapper .status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.manuscript-wrapper .status span button { margin-left: 1rem; }

.manuscript-wrapper .status > * { text-transform: capitalize; }
.manuscript-wrapper .status h3,
.manuscript-wrapper .actions h3 {
  margin: 0;
  position: absolute;
  top: -0.6em;
  display: block;
  isolation: isolate;
}
.manuscript-wrapper .status h3::before,
.manuscript-wrapper .actions h3::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -0.5rem;
  right: -0.5rem;
  background: white;
  z-index: -1;
}
.manuscript-wrapper .reviewer div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.manuscript-wrapper .reviewer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.manuscript-wrapper .manuscript-actions-bar {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.manuscript-wrapper .history {
  padding: 0;
  list-style-type: none;
}
.manuscript-wrapper .history .history-entry {
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
}
.manuscript-wrapper .history .history-entry:not(:first-child) { margin-top: 1rem; }
.manuscript-wrapper .history .history-entry .timestamp {
  display: block;
  width: fit-content;
  position: absolute;
  top: -0.5rem;
  background: white;
  padding: 0 0.5rem;
}

.manuscript-wrapper .tabs {
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 1rem;
  margin: 1rem;
}
.manuscript-wrapper .tabs button.tab-selector {
  background: transparent;
  border: none;
  font-weight: 800;
  font-size: 1.1rem;
  --color-current: var(--color-primary);
  padding: 0.5rem 0;
  border-radius: 0;
}
.manuscript-wrapper .tabs button.tab-selector:hover,
.manuscript-wrapper .tabs button.tab-selector:focus-visible {
  --color-current: var(--color-secondary);
}
.manuscript-wrapper .tabs button.tab-selector:hover,
.manuscript-wrapper .tabs button.tab-selector:focus-visible,
.manuscript-wrapper .tabs button.tab-selector.current {
  color: var(--color-current);
  border-bottom: 5px solid var(--color-current);
}

.manuscript-wrapper .abstract-buttons {
  width: fit-content; 
  margin-left: auto;
}
.manuscript-wrapper .abstract-buttons button {
  margin-left: 5px;
}