.button {
  background-color: var(--button-color-primary);

  --border-color: var(--button-border-color-primary);
  border-color: var(--border-color);
  border-width: 2px;
  border-style: solid;
  border-radius: var(--input-border-radius);

  --color: var(--button-text-color-primary);
  color: var(--color);
  font: normal normal bold 1em var(--font-family-primary);
  letter-spacing: 0px;
  vertical-align: middle;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  min-height: var(--input-min-height);
  line-height: var(--input-line-height);
  flex-grow: 0;

  margin: 9px 0px;
  padding: var(--button-padding);

  opacity: 0.86;
}

.button:disabled {
  cursor: initial;
}

.button .button-content {
  display: inline;
}
.button .button-adornment {
  height: 1em;
  transform: translateY(-.25rem);
}
.button .button-adornment.start {
  translate: -.5rem;
}
.button .button-adornment.end {
  translate: .5rem;
}
@media (max-width: 787px) {
  .button.hide-on-mobile .button-content {
    display: none;
  }
  .button.hide-on-mobile .button-adornment { translate: 0; }
}

.button-primary {
}

.button-secondary {
  background-color: var(--button-color-secondary);
  --border-color: var(--button-border-color-secondary);
  --color: var(--button-text-color-secondary);
}

.button-tertiary {
  background-color: var(--button-color-tertiary);
  --border-color: var(--button-border-color-tertiary);
  --color: var(--button-text-color-tertiary);
}

.button-icon {
  padding: 0;
  height: 2rem;
  width: 2rem;
}
.button-icon .button-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button-icon .button-content * {
  margin: 0;
  padding: 0;
}

.button-hollow {
  color: var(--border-color);
  background-color: transparent;
  position: relative;
  isolation: isolate;
  transition: color 0.2s;
  overflow: hidden;
}
.button-hollow:hover:not([disabled]),
.button-hollow:focus:not([disabled]) {
  color: var(--color);
}
.button-hollow::before {
  content: ' ';
  position: absolute;
  background-color: var(--border-color);
  height: 150%;
  width: 150%;
  transform: translateX(-100%);
  transition: transform 0.2s;
  z-index: -1;
}
.button-hollow:hover:not([disabled])::before,
.button-hollow:focus:not([disabled])::before {
  transform: translateX(0%);
}

.button-full-width { width: 100%; }
.button-home-page {
  border-radius: 50px;
  background-color: transparent;
  opacity: 1;
  position: relative;
  overflow: hidden;
  border: none;
}
.button-home-page::before {
  content: ' ';
  position: absolute;
  height: 150%;
  width: 200%;
  z-index: -1;
  transform: translateX(15%);
  transition: transform 0.3s;

  background-image: linear-gradient(90deg,
    var(--color-primary),
    var(--color-secondary),
    var(--color-secondary));
}
.button-home-page:hover::before,
.button-home-page:focus::before {
  transform: translateX(-15%);
}
