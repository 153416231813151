.sign-in-out-link-wrapper > *,
nav {
  display: flex;
  border-radius: 10px;
  font-weight: 700;
  min-width: 75%;
  z-index: 999;
  align-items: center;

  --fs-top-level: auto;
}

.sign-in-out-link-wrapper ul,
nav ul {
  list-style-type: none;
}

nav > li {
  display: inline;
  flex: 1 1 100%;
  text-align: center;
  height: fit-content;
  font-size: var(--fs-top-level);
}

.sign-in-out-link-wrapper {
  position: relative;
  font-size: var(--fs-top-level);
  min-width: fit-content;
}
.sign-in-out-link {
  min-width: fit-content;
}
.sign-in-out-link svg {
  margin-right: 10px;
}

.sign-in-out-link-wrapper li,
nav li {
  position: relative;
}

.sign-in-out-link-wrapper button,
.sign-in-out-link-wrapper a:link,
.sign-in-out-link-wrapper a,
nav button,
nav a:link,
nav a {
  color: black;
  text-decoration: none;
}

nav button {
  font: inherit;
  text-align: inherit;
  background-color: transparent;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 0;
  margin: 0;
}

nav button:hover,
nav button:focus {
  color: var(--color-link-hover);
}

li a {
  display: block;
}

li a,
li button {
  padding: 10px;
}

nav ul li a:hover,
nav ul li a:focus,
nav ul button:hover,
nav ul button:focus {
  background: rgba(0, 0, 0, 0.10);
}

.nav-list-closed {
  transform: scaleY(0);
  height: 0;
}

@media (max-width: 788px) {
  .sign-out-button.nav-list-closed {
    transform: scaleY(1);
    height: auto;
  }
}
.sign-out-button li { width: 100%; }

.nav-list-open {
  transform: scaleY(1);
}
@media (min-width: 788px) {
  .nav-list-open .nav-list-open {
    position: absolute;
    top: 0;
    width: calc(100%);
    transform: translateX(100%);
    background: white;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    padding: 0px;
  }
}

.nav-list-dropdown {
  position: absolute;
  background-color: white;
  padding: 15px 0;
  margin-top: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  left: 0;
  right: 0;
}

@media (min-width: 788px) {
  nav.on-bottom .nav-list-dropdown { translate: 0 -100%; }
  .close-button-container { display: none; }
}

nav.nav-list-open {
  display: initial;
  padding: 10px;
}
nav.nav-list-open .nav-list-dropdown {
  position: initial;
}
.button.burger { display: none; }
@media (max-width: 787px) {
  nav.nav-open::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: max(100vh, 1100px);
    height: max(100dvh, 1100px);
    backdrop-filter: blur(2px);
    z-index: -1;
    background: var(--color-neutral-light);
  }
  nav.nav-open {
    top: 100px;
    display: block;
    position: fixed;
    overflow: scroll;
    /* left: 0; */
    z-index: 1000;
    box-sizing: border-box;
    margin: 0;
    padding: var(--sizing-padding);
    border-radius: 0;
    justify-content: flex-start;
    inset: 0;
    /* top: calc(var(--header-vertical-margin) + var(--header-max-height)); */
  }
  nav.nav-open > * {
    width: var(--sizing-width);
    margin-left: auto;
    margin-right: auto;
  }
  nav.nav-closed { display: none; }
  .button.burger {
    display: flex;
    margin-left: auto;
  }
  .nav-list-dropdown {
    position: relative;
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
    box-shadow: none;
  }
  nav, nav * {
    box-sizing: border-box;
  }
  nav ul {
    padding: 0;
    margin-left: 1rem;
  }
  nav li {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    margin: 0;
  }
  nav li li {
    border-radius: 0px 5px 5px 0px;
    border-left: 4px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .nav-list-dropdown { border-radius: 0; }
  .close-button-container { display: flex; }

  nav.nav-open.on-bottom {
    bottom: 100px;
    /* 'important' is bad but it's better than
        making the jsx a lot less readable  */
    top: 0 !important;
    display: absolute;
    inset: 0;
  }

}

.close-button-container {
  margin: var(--header-vertical-margin) auto;
  max-height: var(--header-max-height);
  width: var(--sizing-width);
  align-items: center;
  padding: 10px;
}
