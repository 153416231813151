.dvh {
  --border: 1px solid lightgrey;
  border: var(--border);
  border-radius: var(--input-border-radius);
  margin: 15px 0;
  padding-top: 10px;

  --animation-duration: 0.5s;
}

.dvh_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid transparent;
  transition: border-color var(--animation-duration);
}
.dvh_header.open {
  border-bottom: var(--border);
}

.dvh_div {
  padding: 5px 20px;
}

.dvh_toggle {
  width: 10%;
  text-align: center;
}

.dvh_checkbox {
  height: 100%;
}

.dvh_text {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dvh_button {
  width: 20%;
  display: flex;
  flex-direction: row-reverse;
}

.dvh_body {
  overflow: hidden;
  animation-fill-mode: forwards;
  animation-duration: var(--animation-duration);
  position: relative;
  min-height: 0;
}
.dvh_body.closed {
  animation-name: dvh_slide_closed;
}
.dvh_body.open {
  animation-duration: calc(2 * var(--animation-duration));
  animation-name: dvh_slide_open;
}
/* Not sure why, but using one animation with animation-direction: reverse didn't work */
@keyframes dvh_slide_open {
  0% {
    top: -100%;
    opacity: 0;
    max-height: 0px;
  }
  100% {
    top: 0%;
    opacity: 1;
    max-height: 200vw;
  }
}
@keyframes dvh_slide_closed {
  0% {
    top: 0;
    opacity: 1;
    max-height: 1000px;
  }
  100% {
    top: -100%;
    opacity: 0;
    max-height: 0px;
  }
}
