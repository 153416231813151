.reportinterface {
    background-color: #ffffff;
    background-size: cover;
    background-position: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top-width: 0px;
    border-filter: blur(35px);
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: auto;
    max-height: 75vh;
}

.reportinterface__padding{
  margin: 0px 40px;
}

.reportinterface__soloview__padding {
  margin: 10px;
}

.form-text-header {
  padding: 14px 0 10px 0;
  font: normal normal normal 1em Helvetica Neue;
}

.query_select {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: .5em 1em .5em 1em;
  width: 100%;
  font: normal normal normal 16px/18px Helvetica Neue;
  color:  #1D2623;
}
