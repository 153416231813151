.dmm-toaster-wrapper {
  position: fixed;
  width: min(450px, calc(100% - 2rem));
  margin: 1rem;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.dmm-toaster-wrapper.bottomLeft,
.dmm-toaster-wrapper.bottomRight {
  flex-direction: column-reverse;
  bottom: 0;
}
.dmm-toaster-wrapper.topRight,
.dmm-toaster-wrapper.bottomRight {
  right: 0;
}

.dmm-toast-wrapper {
  min-height: 2rem;
  background: white;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  gap: 1rem;
  position: relative;
  overflow: hidden;
  box-shadow: var(--box-shadow-1);
}
.dmm-toast-wrapper.fading {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s var(--fade-time), opacity var(--fade-time) linear;
}
.dmm-toast-wrapper.faded {
  visibility: hidden;
  opacity: 0;
  height: 0px;
  min-height: 0px;
  margin: 0;
  padding: 0;
  transition: margin var(--fade-time), padding var(--fade-time), min-height var(--fade-time);
}

.dmm-toast-wrapper button {
  margin-left: auto;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid;
  border-color: transparent;
  transition: background-color 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
}
.dmm-toast-wrapper button:hover,
.dmm-toast-wrapper button:focus {
  border-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
}

.error.dmm-toast-wrapper,
.error.dmm-toast-wrapper:hover,
.error.dmm-toast-wrapper:focus {
  outline: none;
  border: none; /* temp kludgy solution to overwriting from Input.css */
}

.dmm-toast-timer-bar-background {
  position: absolute;
  height: 5px;
  bottom: 0;
  left: 0;
  right: 0;
}
.dmm-toast-timer-bar-foreground {
  background-color: var(--color-toast-message, blue);
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  transition: width 0.5s linear;
}
.error .dmm-toast-timer-bar-foreground {
  background-color: var(--color-toast-error, red);
}
.success .dmm-toast-timer-bar-foreground {
  background-color: var(--color-toast-success, darkgreen);
}
