.raw-markdown-editor,
.milkdown .editor {
  border: 1px solid var(--input-border-color);
  padding: 1rem;
  padding-bottom: 4rem;
  min-height: 50dvh;
  width: 100%;
  box-sizing: border-box;
}

.raw-markdown-editor {
  white-space: break-spaces;
  overflow: auto;
}
