.loading-animation-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-animation-div img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}
