.background-image {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.background-image::before,
.background-image::after {
  content: ' ';
  inset: 0;
  z-index: -3;
}
.background-image::before {
  position: fixed;
  background-color: var(--background-color);
}
.background-image::after {
  position: absolute;
  top: 2rem;
  background-image: linear-gradient(to bottom, transparent, transparent, var(--background-color)),
    var(--background-image);
  background-size: 100dvw auto;
  background-repeat: no-repeat;
}
