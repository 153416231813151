.masthead ul {
  list-style-type: none;
  padding: 0;
}
.masthead li {
  margin: 1rem 0;
}
.masthead section h3 {
  font-size: 1.75rem;
}
