.login_box {
  min-width: fit-content;
  width: 600px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 40px;
  margin: 40px auto;
}
.login_box h2,
.login_box p { text-align: center; }
