.dashboard-wrapper .zoom-control-wrapper {
  background: var(--dashboard-zoom-bar-background, rgba(0, 0, 0, 0.1));
  border-radius: 15px;
  min-width: fit-content;
  padding: 5px;
  height: fit-content;

  border: 1px solid var(--dashboard-zoom-bar-border-color, transparent);
  --item-width: 50px;
}

.dashboard-wrapper .zoom-control-wrapper > svg {
  display: block;
  width: var(--item-width);
  height: var(--item-width);
  margin: auto;
}

.dashboard-wrapper .zoom-control-wrapper .range-wrapper {
  position: relative;
  --range-height: 35rem;
  height: var(--range-height);
  width: var(--item-width);
  overflow: visible;
  margin: auto;
}
.dashboard-wrapper .zoom-control-wrapper input[type=range] {
  --thumb-width: 36px;
  --thumb-height: var(--thumb-width);
  --thumb-border: none;
  --thumb-border-radius: 50%;
  --thumb-background: var(--dashboard-zoom-bar-thumb-color, #4b4a90);

  --track-width: calc(var(--range-height) - var(--thumb-height) * 2);
  --track-height: calc(var(--thumb-width) - 10px);
  --track-background: var(--dashboard-zoom-bar-track-background, var(--color-primary));

  /* idk man i just work here */
  --magic-number-one: 46px;
  --magic-number-two: 7px;
  min-width: var(--range-height);
  --offset: calc((var(--range-height) - var(--magic-number-one)) / 2);
  left: calc(var(--offset) * -1);
  top: calc(var(--offset) - var(--magic-number-two));
  transform: rotate(270deg);
  position: absolute;
  outline: none;
}

/* BEGIN OVERRIDES */
.dashboard-wrapper .zoom-control-wrapper input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}
/* END OVERRIDES */

/* BEGIN THUMB */
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  border: var(--thumb-border);
  background: var(--thumb-background);
  cursor: pointer;
  margin-top: calc((var(--track-height) - var(--thumb-height)) / 2); /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  border: var(--thumb-border);
  background: var(--thumb-background);
  cursor: pointer;
}

/* All the same stuff for IE */
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  border: var(--thumb-border);
  background: var(--thumb-background);
  cursor: pointer;
}
/* END THUMB */

/* BEGIN TRACK */
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-webkit-slider-runnable-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  background: var(--track-background);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--track-background);
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]::-moz-range-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  background: var(--track-background);
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.dashboard-wrapper .zoom-control-wrapper input[type=range]::-ms-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-ms-fill-lower {
  background: var(--track-background);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
.dashboard-wrapper .zoom-control-wrapper input[type=range]:focus::-ms-fill-lower {
  background: var(--track-background);
}
.dashboard-wrapper .zoom-control-wrapper input[type=range]::-ms-fill-upper {
  background: var(--track-background);
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
.dashboard-wrapper .zoom-control-wrapper input[type=range]:focus::-ms-fill-upper {
  background: var(--track-background);
}
/* END TRACK */
