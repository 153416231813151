.inline-message.hint { --border-color: skyblue; }
.inline-message.warning { --border-color: orange; }
.inline-message.error { --border-color: red; }
.inline-message.success { --border-color: var(--color-success-border); }
.inline-message.pending { --border-color: grey; }

.inline-message {
  border-radius: var(--input-border-radius);
  margin: 1rem 0px;
  padding: 10px;
  position: relative;
  background: transparent;
  isolation: isolate;

  display: flex;

  border-left: 5px solid var(--border-color);
}
.inline-message::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--border-color);
  opacity: 0.1;
  z-index: -1;
  border-radius: inherit;
}
/* kludgey fix to interference from Input.css */
.inline-message.error,
.inline-message.error:hover { outline: none; }

.inline-message > svg {
  color: var(--border-color);
  vertical-align: text-bottom;
  margin-right: 1rem;
}
.inline-message.pending svg {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

button.inline-message {
  cursor: pointer;
  width: 100%;
}

.inline-message.arrow::before {
  position: absolute;
  content: ' ';
  --length: 0.75rem;
  width: var(--length);
  height: var(--length);
  background-color: var(--background-color);
  transform: rotate(45deg);
}
.inline-message.arrow.top::before {
  top: -0.5rem;
  left: calc(50% - (var(--length) / 2));
  border-left: var(--border);
  border-top: var(--border);
}

.inline-message > button.close-button {
  margin-left: auto;
  background: transparent;
  border: none;
  border-radius: 5px;
}
.inline-message > button.close-button:hover,
.inline-message > button.close-button:focus-visible {
  background: rgba(0, 0, 0, 0.2);
}
