.solutions_box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px #00000029;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.solutions_video {
  border: 1px solid rgba(0, 0, 0, 0.27);
  border-radius: 10px;
}

.interior_margin {
    display: flex;
    padding: 25px 50px 25px 50px;
    margin: 15px 0px 15px 0px;
}

li {
    text-align: left;
}

.solutions-wrapper {
    position: relative;
    left: calc((100vw - var(--sizing-width)) * -1 / 2);
    overflow-x: hidden;
}

.solutions-text {
    background: #1a2124;
    border-radius: 0;
    color: white;
}

.solutions-text h1 {
    color: var(--color-secondary);
}

.solutions-text h3 {
    font-style: italic;
}

@media (max-width: 1007px) {
    .interior_margin {
        flex-direction: column-reverse;
    }
}

@media (min-width: 1008px) {
    .interior_margin {
        flex-direction: row-reverse;
        max-width: 40%
    }

    .solutions-text {
        background-image: url("../../images/dmm_aboutbg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

.solutions-text {
    min-height: calc(100dvh - 120px);
    padding: 0;
    margin: 0;
}

.solutions-text a {
    color: var(--color-secondary);
}

.solutions-graph {
    padding: 0 10dvw;
}